import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en_US from './locales/en_US.json';
import zh_CN from './locales/zh_CN.json';

// let lang = localStorage.getItem("i18nextLng");
// if (!lang) {
//     lang = 'zh_CN';
//     localStorage.setItem("i18nextLng", 'zh_CN');
// }
i18n
    .use(LanguageDetector) // 检测当前浏览器语言
    .use(initReactI18next) //初始化
    .init({
        // 引入资源文件
        resources: {
            // enGB:{
            //     translation: en_US,
            // },
            en: {
                translation: en_US,
            },
            // enUS:{
            //     translation: en_US,
            // },
            // en_US: {
            //     translation: en_US,
            // },
            zh_CN: {
                translation: zh_CN,
            },
        },
        fallbackLng: 'zh_CN', // 备用语言，若要加载的语言存在某个键值缺失，则用fallbackLng来代替
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        // lng: "en",
        // lng: "zh_CN",
    });

export default i18n;