import React from "react";
import i18n from "i18n";
import { windowsUrl, macUrl, androidTvUrl } from "config";
import { Introduction } from "components";
import styles from "./index.module.css";

interface IState {
    showMobileQrcode: boolean;
    showMobileQrcodeIos: boolean;
}

class Page404 extends React.Component {

    public state: IState = {
        showMobileQrcode: false,
        showMobileQrcodeIos: false,
    }
    public render() {
        return <div className={styles.downloadPage}>
            <div className={styles.banner}>
                {i18n.t('downloadMsg')}
            </div>

            <div className={styles.list}>
                <a className={styles.item} href={windowsUrl}>
                    <img src={require("static/images/download/windows.png")} alt="" />
                    <div className={styles.text}>Windows</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
                <a className={styles.item} href={macUrl}>
                    <img style={{ width: "0.52rem", height: "0.64rem" }} src={require("static/images/download/ios.png")} alt="" />
                    <div className={styles.text}>macOS</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
                <div className={styles.item} onMouseLeave={() => this.setState({ showMobileQrcode: false })}>
                    {
                        this.state.showMobileQrcode ? <img className={styles.qrImg}
                            src={require(`static/images/home/download-svmeet.png`)} alt="" /> :
                            <img style={{ width: "0.6457rem", height: "0.70rem" }} src={require("static/images/download/android.png")} alt="" />
                    }
                    <div className={styles.text}>Android</div>
                    {
                        !this.state.showMobileQrcode && <div className={styles.btn}
                            onMouseOver={() => this.setState({ showMobileQrcode: true })}
                        >{i18n.t('scanIt')}</div>
                    }

                    {
                        this.state.showMobileQrcode && <div className={styles.text2}>{i18n.t('phoneScanDownload')}</div>
                    }

                </div>
                <div className={styles.item} style={{ height: this.state.showMobileQrcodeIos ? "3.57rem" : "2.80rem" }} onMouseLeave={() => this.setState({ showMobileQrcodeIos: false })}>
                    {
                        this.state.showMobileQrcodeIos ? <img className={styles.qrImg}
                            src={require(`static/images/home/download-ios.png`)} alt="" /> :
                            <img style={{ width: "0.6457rem", height: "0.70rem" }} src={require("static/images/download/appstore.png")} alt="" />
                    }
                    <div className={styles.text}>iOS</div>
                    {
                        !this.state.showMobileQrcodeIos && <div className={styles.btn}
                            onMouseOver={() => this.setState({ showMobileQrcodeIos: true })}
                        >{i18n.t('scanIt')}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.text2}>{i18n.t('phoneScanDownload')}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or} style={{ marginTop: "0.16rem" }}>{i18n.t("or")}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or}>{i18n.t("AppSearch")}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or}>“{i18n.t("svmeet")}”</div>
                    }
                </div>
                <a className={styles.item} href={androidTvUrl}>
                    <img style={{ width: "0.80rem", height: "0.60rem" }} src={require("static/images/download/tv.png")} alt="" />
                    <div className={styles.text}>Android  TV</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
                {/* <div className={styles.item}>
                    <img style={{ width: "0.70rem", height: "0.70rem" }} src={require("static/images/download/appstore.png")} alt="" />
                    <div className={styles.text}>iOS</div>
                    <div className={styles.btn1}>{i18n.t('scanIt')}</div>
                </div> */}
            </div>
            <Introduction />
        </div>
    }
}

export default Page404;