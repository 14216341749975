
import React from "react";
import { withRouter } from "react-router-dom";
import { Menu } from 'antd';
import "./index.css"
const { SubMenu } = Menu;

const menu = [
    {
        title: "产品中心",
        key: "1",
        disabled: false,
        path: "/",
        children: [{
            title: "M120",
            key: "1-1",
            path: "/m120"
        }, {
            title: "G10",
            key: "1-2",
            path: "/g10"
        }, {
            title: "随闻悦见",
            key: "1-3",
            path: "/introduction"
        }]
    }, {
        title: "解决方案",
        key: "2",
        disabled: false,
        children: [],
        path: "/solution"
    }, {
        title: "下载中心",
        key: "3",
        disabled: false,
        children: [],
        path: "/download"
    }, {
        title: "支持中心",
        key: "4",
        disabled: true,
        children: [],
        path: ""
    }, {
        title: "关于我们",
        key: "5",
        disabled: false,
        children: [],
        path: "/about"
    },
]

interface IState{
    selectedKeys: string[];
}
class Index extends React.Component<any> {

    public state:IState={
        selectedKeys: []
    }

    public handleClick(e) {
        console.log(e.key)
        this.setState({ selectedKeys: [e.key] })
        menu.forEach(x => {
            if (x.key === e.key) {
                this.props.history.push(x.path)
            } else {
                x.children.forEach(z => {
                    if (z.key === e.key) {
                        this.props.history.push(z.path)
                    }
                })
            }
        })
    }

    public render() {
        return (
            <div className="navBar">
                <img className="logo" src={require("static/images/logo2.png")} onClick={()=>this.props.history.push("/")}/>
                <Menu style={{ color: "#414141", fontSize: "0.18rem" }} onClick={this.handleClick.bind(this)} selectedKeys={this.state.selectedKeys} mode="horizontal">
                    {
                        menu.map(ele => {
                            if (!ele.children.length) {
                                return <Menu.Item key={ele.key} disabled={ele.disabled}>{ele.title}</Menu.Item>
                            } else {
                                return <SubMenu key={ele.key} title={ele.title}>
                                    {
                                        ele.children.map(x => {
                                            return <Menu.Item key={x.key}>{x.title}</Menu.Item>
                                        })
                                    }
                                </SubMenu>
                            }
                        })
                    }
                </Menu>
            </div>
        );
    }
}

export default withRouter(Index)
