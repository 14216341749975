import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

interface IState {
}

class Index extends React.Component {

    public state: IState = {
    }

    public render() {
        return <div className={styles.g10Page}>
            <div className={styles.banner}>
                <div>{i18n.t('introduction.g101')}</div>
                <div>{i18n.t('introduction.g102')}</div>
            </div>

            <div className={styles.block1}>
                <div>1080P<br />@30fps</div>
                <div>H264<br />H265</div>
                <div dangerouslySetInnerHTML={{ __html: i18n.t("introduction.g103") }}></div>
                <div dangerouslySetInnerHTML={{ __html: i18n.t("introduction.g104") }}></div>
                <div dangerouslySetInnerHTML={{ __html: i18n.t("introduction.g105") }}></div>
            </div>

            <div className={styles.block2}>
                <div className={styles.title}>
                    <div>{i18n.t("introduction.g106")}</div>
                    <div style={{ textAlign: "right" }}>{i18n.t("introduction.g107")}</div>
                </div>

                <div className={styles.btns}>
                    <div>{i18n.t("introduction.g108")}</div>
                    <div>{i18n.t("introduction.g109")}</div>
                </div>
            </div>

            <div className={styles.block3}>
                <div className={styles.title}>
                    <span>{i18n.t("introduction.g1010")}</span>  |  <span>{i18n.t("introduction.g1011")}</span>  |  <span>{i18n.t("introduction.g1012")}</span>  |  <span>{i18n.t("introduction.g1013")}</span>
                </div>
                <div className={styles.imgs}>
                    <img src={require("static/images/product/gaowen.PNG")} alt="" />
                    <img src={require("static/images/product/IP56.PNG")} alt="" />
                    <img src={require("static/images/product/rohs.PNG")} alt="" />
                    <img src={require("static/images/product/SRRC.PNG")} alt="" />
                </div>
            </div>

            <Introduction style={{ position: "initial", padding: "0.17rem 0 0.35rem" }} />
        </div>
    }
}

export default withRouter(Index);