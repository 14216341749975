import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

interface IState {
    showMobileQrcode: boolean;
    currentMenu: 1 | 2 | 3 | 4;
}

class Index extends React.Component {

    public state: IState = {
        showMobileQrcode: false,
        currentMenu: 1
    }

    public render() {
        const { currentMenu } = this.state
        return <div className={styles.solutionPage}>
            <div className={styles.banner}>
                <span>
                    {i18n.t('solution')}
                </span>
            </div>

            <div className={styles.menuNav}>
                <span style={{ color: currentMenu === 1 ? "#3C68E3" : "#5C5959" }} onClick={() => {
                    this.setState({ currentMenu: 1 })
                }}>{i18n.t("solution1")}</span>
                <span style={{ color: currentMenu === 2 ? "#3C68E3" : "#5C5959" }} onClick={() => {
                    this.setState({ currentMenu: 2 })
                }}>{i18n.t("solution2")}</span>
                <span style={{ color: currentMenu === 3 ? "#3C68E3" : "#5C5959" }} onClick={() => {
                    this.setState({ currentMenu: 3 })
                }}>{i18n.t("solution3")}</span>
                <span style={{ color: currentMenu === 4 ? "#3C68E3" : "#5C5959" }} onClick={() => {
                    this.setState({ currentMenu: 4 })
                }}>{i18n.t("solution4")}</span>
            </div>
            {
                currentMenu === 1 && <div className={styles.developmentPath}>
                    <div className={styles.title}>{i18n.t("solutionText.title")}</div>
                    <div className={styles.box}>
                        <div className={styles.item} style={{ marginBottom: "0.33rem" }}>
                            <div>{i18n.t("solutionText.msg1")}</div>
                            <div>{i18n.t("solutionText.msg2")}</div>
                        </div>
                        <div className={styles.item} style={{ marginLeft: "0.73rem", marginBottom: "0.33rem" }}>
                            <div>{i18n.t("solutionText.msg3")}</div>
                            <div>{i18n.t("solutionText.msg4")}</div>
                        </div>
                        <div className={styles.item}>
                            <div>{i18n.t("solutionText.msg5")}</div>
                            <div>{i18n.t("solutionText.msg6")}</div>
                        </div>
                        <div className={styles.item} style={{ marginLeft: "0.73rem" }}>
                            <div>{i18n.t("solutionText.msg7")}</div>
                            <div>{i18n.t("solutionText.msg8")}</div>
                        </div>
                        <div className={styles.circle} dangerouslySetInnerHTML={{ __html: i18n.t("solutionText.msg9") }}>
                        </div>
                    </div>
                </div>
            }

            {
                currentMenu === 1 &&
                <div className={styles.visionMission}>
                    <div className={styles.title}>{i18n.t("solutionText.msg10")}</div>
                    <div className={styles.msg}><span className={styles.circle}></span>{i18n.t("solutionText.msg11")}</div>
                    <div className={styles.msg}><span className={styles.circle}></span>{i18n.t("solutionText.msg12")}</div>
                    <div className={styles.msg}><span className={styles.circle}></span>{i18n.t("solutionText.msg13")}</div>
                </div>}
            {
                currentMenu === 1 &&
                <div className={styles.contactUs}>
                    <div className={styles.title}>{i18n.t("solutionText.title1")}</div>
                    <div className={styles.list}>
                        <div className={styles.item}>
                            <img className={styles.img5} src={require("static/images/solution/room1.png")} alt="" />
                            <span>30平米以内小型会议室</span>
                        </div>
                        <div className={styles.item}>
                            <img className={styles.img6} src={require("static/images/solution/room2.png")} alt="" />
                            <span>30-60平米中小型会议室</span>
                        </div>
                        <div className={styles.item}>
                            <img className={styles.img7} src={require("static/images/solution/room3.png")} alt="" />
                            <span>60平米以上大型会议室</span>
                        </div>
                    </div>
                </div>}

            {
                currentMenu === 2 && <div className={styles.remote}>
                    <div>
                        音视频双向对讲<br/>
                        多场景视频图像增强<br/>
                        行业作业规范与指导<br/>
                        行业机器视觉算法
                    </div>
                </div>
            }

            {
                currentMenu === 3 && <div className={styles.security}>
                    <div className={styles.top}>
                        <div className={styles.tag1}><img src={require("static/images/solution/icon1.png")} alt="" />人脸/人体，车辆/车牌检测</div>
                        <div className={styles.tag2}><img src={require("static/images/solution/icon2.png")} alt="" />降低视频专网流量使用</div>
                    </div>
                    <div className={styles.bottom}>
                        <img src={require("static/images/solution/icon3.png")} alt="" />配套警务通本地人脸识别, 实现 3 万离线人脸底库秒级识别
                    </div>
                </div>
            }

            {
                currentMenu === 3 && <div className={styles.security1}><div></div></div>
            }

            {
                currentMenu === 4 && <div className={styles.emergency}><div></div></div>
            }


            <Introduction style={{ position: "initial", padding: "0.17rem 0 0.35rem" }} />
        </div>
    }
}

export default withRouter(Index);