
import React from "react";
import i18n from "i18n";
import { routes } from "../config/route";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import zh_CN from 'antd/lib/locale/zh_CN';
import ConfigProvider from "antd/lib/config-provider";
import Page404 from "pages/404";
import { NavBar } from "components";
import Layout from "antd/lib/layout";
const { Content } = Layout;

let isMobilePage = false;
class Index extends React.Component<any> {

    constructor(props) {
        super(props)
        // const pc = this.IsPC()
        // const is_weixin = this.is_weixin()
        // if (!pc) {
        //     if (!is_weixin) {
        //         // if(window.location.pathname.indexOf('/sv/') > -1){
        //         isMobilePage = true;
        //         // }
        //     }
        // }
    }
    public state = {
        language: zh_CN
    }

    public async componentWillMount() {
        // const user = await api.user.getUserInfo();
        // if (user.code === 200) {
        //     this.props.setUser(user.data)
        // }
        // if (this.IsPC()) {
        //     if (window.location.pathname.indexOf('/sv/') > -1) {
        //         window.history.replaceState({}, '', window.location.origin + `#/join-meet/` + window.location.pathname.replace("/sv/", "") + window.location.search)
        //     }
        // }
    }

    public is_weixin() {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i)) {
            return true;
        }
    }

    public IsPC() {
        const userAgentInfo = navigator.userAgent;
        const Agents = ['Mobile'
            // "Android", "iPhone",
            // "SymbianOS", "Windows Phone",
        ];//"iPad","iPod"
        let flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    public async componentDidMount() {
        // if (this.IsPC()) {
        //     window.addEventListener('beforeunload', () => {
        //         this.props.setNoticeSocketObj(null)
        //     })
        //     // window.addEventListener('load', () => {
        //     //     this.websocketNotice()
        //     // })
        // }


        // antd语言
        // let num = 0;
        // navigator.languages.forEach((node: string) => {
        //     if (num === 0) {
        //         if (node.split('-')[0] === 'zh') {
        //             num += 1
        //             this.setState({ language: zh_CN })
        //             // 设置日期组件
        //             moment.locale('zh-cn');
        //         }
        //         if (node.split('-')[0] === 'en') {
        //             num += 1
        //             this.setState({ language: en_GB })
        //         }
        //     }
        // })
        // title修改
        const svmeetTitle = document.getElementById('svmeet-title');
        if (svmeetTitle) svmeetTitle.innerText = i18n.t('svmeet')

        // 检查token

        // const search = window.location.href.slice(window.location.href.indexOf("?")).replace("?", "").split('&')
        // const obj = {}
        // if (search.length) {
        //     search.forEach(element => {
        //         const arr = element.split("=")
        //         obj[arr[0]] = arr[1]
        //     });
        //     const res = await api.user.checkToken()
        //     if (res.code === 200) {
        //         if (res.data === false) {
        //             localStorage.removeItem(STORAGE_ACCESS_TOKEN)
        //             if (obj['token']) {
        //                 window.localStorage.setItem(STORAGE_ACCESS_TOKEN, obj['token']);
        //                 window.location.reload()
        //             }
        //         } else {
        //             if (obj['token']) {
        //                 if (window.localStorage.getItem(STORAGE_ACCESS_TOKEN) !== obj['token']) { //不同用户 
        //                     window.localStorage.setItem(STORAGE_ACCESS_TOKEN, obj['token']);
        //                     window.location.reload()
        //                 }
        //             }
        //         }

        //     }
        // }

    }
    public render() {
        return (
            <ConfigProvider locale={this.state.language}>
                <NavBar />
                <Layout style={{
                    // padding: "0 0.36rem", 
                    height: "calc(100vh - 0.55rem)",
                    background: "#FBFCFF",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "scroll"
                }} >
                    <Content style={{ width: "10.04rem" }}>
                        <Switch>
                            {routes.map((route) => (
                                <Route exact={true} path={route.path} component={route.component} key={route.path} />
                            ))}
                            <Route path="*" component={Page404} />
                        </Switch>
                    </Content>
                </Layout>
            </ConfigProvider>
        );
    }

    public noticeSocket: any
    public pingTimer;
}

export default Index