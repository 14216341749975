import React from "react";
import { withRouter } from 'react-router-dom';
import i18n from "i18n";
import { Menu } from 'antd';
import "./index.css";
import moment from "moment";

class Index extends React.Component<{
    history: any;
    style?: any
}, any> {
    constructor(props) {
        super(props);
        this.state = {
            svTextModal: false,
        };
    }

    componentDidMount() {

    };

    goDownload() {
        this.props.history.push("/download")
    }
    goSolution() {
        this.props.history.push("/solution")

    }
    goAbout() {
        this.props.history.push("/about")
    }

    render() {
        return <div className="introduction-component">
            <div className="contain" data-flex="main:justify">
                <div data-flex="main:left">
                    <div className='v1 item'>
                        <div className="title">产品</div>
                        <div onClick={this.goDownload.bind(this)} style={{ marginBottom: " 0.2rem", cursor: "pointer" }}>下载中心</div>
                        <div onClick={this.goSolution.bind(this)} style={{ cursor: "pointer" }}>解决方案</div>
                    </div>
                    <div className='v2 item'>
                        <div className="title">帮助</div>
                        <div style={{ marginBottom: " 0.2rem",cursor: "pointer" }}><a target="_blank"  href="https://www.svmeet.com/faq/html/111/index.html" >帮助中心</a></div>
                        <div style={{ marginBottom: " 0.2rem",cursor: "pointer" }}><a target="_blank"  href={`https://www.svmeet.com/suiwen/${i18n.t('privacyUrl')}.html`}>隐私政策</a></div>
                        {/* <div style={{ cursor: "pointer" }}>用户协议</div> */}

                        {/* <Dropdown overlay={this.menuRender} placement="topCenter" arrow><div style={{ cursor: "pointer" }}>{i18n.t("Instructions")}</div></Dropdown> */}
                    </div>
                    <div className="v3 item">
                        <div className="title">关于</div>
                        <div onClick={this.goAbout.bind(this)} style={{ marginBottom: " 0.2rem", cursor: "pointer" }}>关于我们</div>
                        <div onClick={this.goAbout.bind(this)} style={{ cursor: "pointer" }}>联系我们</div>
                    </div>
                </div>
                <div className="v4">
                    <img src={require("static/images/WechatIMG.jpeg")} alt="" />
                </div>
            </div>
            <div className="beian"><a style={{ color: '#767676' }} className="beian" target="_blank" href="https://beian.miit.gov.cn">©2021-{moment().format("YYYY")} 北京随闻科技有限公司 | 京ICP备18026489号-2</a></div>
        </div>
    };

    menuRender() {
        return (<Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/WebUserManual.pdf">
                    {i18n.t('WebVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/PCUserManual.pdf">
                    {i18n.t('PcVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/MobileUserManual.pdf">
                    {i18n.t('MobileVersionManual')}
                </a>
            </Menu.Item>
        </Menu>)
    }
}

export default withRouter(Index)