import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

interface IState {
    showMobileQrcode: boolean;
}

class Index extends React.Component {

    public state: IState = {
        showMobileQrcode: false
    }

    public miao(anchor, menuText) {

        const menu1 = document.getElementById("menu1")
        if (menu1) {
            menu1.style.color = menuText === "menu1" ? "rgba(60, 104, 227, 1)" : "#343434"
        }
        const menu2 = document.getElementById("menu2")
        if (menu2) {
            menu2.style.color = menuText === "menu2" ? "rgba(60, 104, 227, 1)" : "#343434"
        }
        const menu3 = document.getElementById("menu3")
        if (menu3) {
            menu3.style.color = menuText === "menu3" ? "rgba(60, 104, 227, 1)" : "#343434"
        }
        if (!!anchor) {
            let anchorElement = document.getElementById(anchor);
            if (anchorElement) {
                console.log(anchorElement.offsetTop)
                document.body.scrollTo(0, anchorElement.offsetTop - window.innerHeight / 2);
            }
        }
        // 没有的话，滚动到头部
        else {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }

    }
    public render() {
        return <div className={styles.aboutPage}>
            <div className={styles.banner}>
                <span>
                    {i18n.t('AboutUs')}
                </span>
            </div>

            <div className={styles.menuNav}>
                <span id="menu1" onClick={() => {
                    this.miao("developmentPath", "menu1")
                }}>{i18n.t("developmentPath")}</span>
                <span id="menu2" onClick={() => {
                    this.miao("visionMission", "menu2")
                }}>{i18n.t("VisionMission")}</span>
                <span id="menu3" onClick={() => {
                    this.miao("contactUs", "menu3")
                }}>{i18n.t("contactUs")}</span>
            </div>

            <div id="developmentPath" className={styles.developmentPath}>
                <div className={styles.title}>{i18n.t("developmentPath")}</div>
                <img src={require("static/images/about/path.png")} alt="" />
            </div>

            <div id="visionMission" className={styles.visionMission}>
                <div className={styles.title}>{i18n.t("VisionMission")}</div>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <img className={styles.img1} src={require("static/images/about/earth.png")} alt="" />
                        <span className={styles.value}>{i18n.t("Vision")}</span>
                        <span>{i18n.t("VisionMsg")}</span>
                    </div>
                    <div className={styles.item}>
                        <img className={styles.img2} src={require("static/images/about/rocket.png")} alt="" />
                        <span className={styles.value}>{i18n.t("Mission")}</span>
                        <span>{i18n.t("MissionMsg")}</span>
                    </div>
                    <div className={styles.item}>
                        <img className={styles.img3} src={require("static/images/about/core.png")} alt="" />
                        <span className={styles.value}>{i18n.t("CoreView")}</span>
                        <span>{i18n.t("CoreViewMsg")}</span>
                    </div>
                </div>
            </div>

            <div id="contactUs" className={styles.contactUs}>
                <div className={styles.title}>{i18n.t("contactUs")}</div>
                <div className={styles.list}>
                    {/* <div className={styles.item}>
                        <img className={styles.img4} src={require("static/images/about/phone.png")} alt="" />
                        <span>400-1234-123</span>
                    </div> */}
                    <div className={styles.item}>
                        <img className={styles.img5} src={require("static/images/about/mail.png")} alt="" />
                        <span>bd@svmeet.com</span>
                    </div>
                    <div className={styles.item}>
                        <img className={styles.img6} src={require("static/images/about/address.png")} alt="" />
                        <span style={{ width: "2.64rem", display: "block", textAlign: "center" }}>北京市海淀区后屯路28号院1号楼116室</span>
                    </div>
                </div>
                <div style={{ display: "flex" }}> <img className={styles.map} src={require("static/images/about/map.png")} alt="" /></div>
            </div>
            <Introduction style={{ position: "initial", padding: "0.17rem 0 0.35rem" }} />
        </div>
    }
}

export default withRouter(Index);