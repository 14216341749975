
import React from "react";
import modal from "antd/lib/modal"
import "./index.css"
import { mobileDownUrl } from "config/index";
import i18n from "i18n";

export default class extends React.Component {

    public isAndroid() {
        const u = navigator.userAgent;
        return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
    }

    public isInstalled() {
        const meetId = window.location.pathname.replace("/sv/", "")
        const params = window.location.href.slice(window.location.href.indexOf("?") + 1);
        modal.confirm({
            title: i18n.t('openApp'),
            okText: <a href={`svmeet://mtime/detail?meetid=${meetId}&${params}`}>{i18n.t('turnOn')}</a>,
            // <a href={this.isAndroid() ? mobileDownUrl : 'itms-apps://itunes.apple.com/cn/app/id1544705219?mt=8'}>打开</a>,
            cancelText: i18n.t('download'),
            onCancel: () => {
                // window.location.href = `svmeet://mtime/detail?meetid=${meetId.length === 6 ? meetId : ''}`;//打开某手机上的某个app应用
                if (this.isAndroid()) {
                    window.location.href = mobileDownUrl;//如果超时就跳转到app下载页
                } else {
                    window.location.href = 'itms-apps://itunes.apple.com/cn/app/id1544705219?mt=8'
                }
            }
        })
        // window.location.href = `svmeet://mtime/detail?meetid=${meetId.length === 6 ? meetId : ''}`;//打开某手机上的某个app应用
        // const timer = setTimeout(() => {
        //     if (this.isAndroid()) {
        //         window.location.href = mobileDownUrl;//如果超时就跳转到app下载页
        //     } else {
        //         window.location.href = 'itms-apps://itunes.apple.com/cn/app/id1544705219?mt=8'
        //     }
        //     clearTimeout(timer)
        // }, 600);
    }

    public render() {
        return <div className="mobile-component">
            <img src={require('static/images/home/mobile-logo.png')} alt="" />
            <span className="text">Nice to meet you</span>
            <div className="button" onClick={this.isInstalled.bind(this)}>{i18n.t('openOrDownloadAPP')}</div>
        </div>
    }

}