import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import styles from "./index.module.css";

interface IState {
    currentMenu: 1 | 2 | 3 | 4;
}

class Page404 extends React.Component {

    public state: IState = {
        currentMenu: 1
    }

    public render() {
        const { currentMenu } = this.state;
        return <div className={styles.introductionPage}>
            <div className={styles.banner}>
                <div style={{ marginTop: '.7rem', fontWeight: 'bold' }}>{i18n.t('svmeet')}</div>
                <div style={{ marginTop: '0.2rem' }}><span style={{ color: '#3C68E3' }}>{i18n.t("introduction.banner")}</span>
                    <span style={{ color: '#9298AC' }}>{i18n.t("introduction.banner1")}</span></div>
                <a className={styles.button} target="_blank" href='https://www.svmeet.com' >立即体验</a>
            </div>
            <div className={styles.main}>
                <div className={styles.menu}>
                    <div className={(currentMenu === 1) ? styles.active + ' ' + styles.item : styles.item} onClick={() => this.setState({ currentMenu: 1 })}>
                        {
                            currentMenu === 1 ?
                                <img className={styles.img} src={require("static/images/product/menu-icon1-a.png")} alt="" /> :
                                <img className={styles.img} src={require("static/images/product/menu-icon1.png")} alt="" />
                        }
                        {i18n.t("introduction.many")}
                    </div>
                    <div className={(currentMenu === 2) ? styles.active + ' ' + styles.item : styles.item} onClick={() => this.setState({ currentMenu: 2 })}>
                        {
                            currentMenu === 2 ?
                                <img className={styles.img} src={require("static/images/product/menu-icon2-a.png")} alt="" /> :
                                <img className={styles.img} src={require("static/images/product/menu-icon2.png")} alt="" />
                        }
                        {i18n.t("introduction.quick")}
                    </div>
                    <div className={(currentMenu === 3) ? styles.active + ' ' + styles.item : styles.item} onClick={() => this.setState({ currentMenu: 3 })}>
                        {
                            currentMenu === 3 ?
                                <img className={styles.img} src={require("static/images/product/menu-icon3-a.png")} alt="" /> :
                                <img className={styles.img} src={require("static/images/product/menu-icon3.png")} alt="" />
                        }
                        {i18n.t("introduction.peace")}
                    </div>
                    <div className={(currentMenu === 4) ? styles.active + ' ' + styles.item : styles.item} onClick={() => this.setState({ currentMenu: 4 })}>
                        {
                            currentMenu === 4 ?
                                <img className={styles.img} src={require("static/images/product/menu-icon4-a.png")} alt="" /> :
                                <img className={styles.img} src={require("static/images/product/menu-icon4.png")} alt="" />
                        }
                        {i18n.t("introduction.province")}
                    </div>
                </div>
                {
                    currentMenu === 1 && <div className={styles.content1}>
                        <div className={styles.title}>{i18n.t("introduction.title1")}</div>
                        <div className={styles.des}>{i18n.t("introduction.title1-1")}</div>
                        <div className={styles.list}>
                            <div className={styles.item} >
                                <img className={styles.img} src={require("static/images/product/product1-1.png")} alt="" />
                                <div className={styles.des1} >{i18n.t("introduction.title1-2")}</div>
                                <div className={styles.des2}>{i18n.t("introduction.title1-3")}</div>
                            </div>
                            <div className={styles.item + ' ' + styles.active}>
                                <img className={styles.img} src={require("static/images/product/product1-2.png")} alt="" />
                                <div className={styles.des1} >{i18n.t("introduction.title1-4")}</div>
                                <div className={styles.des2}>{i18n.t("introduction.title1-5")}</div>
                            </div>
                        </div>
                    </div>
                }
                {
                    currentMenu === 2 && <div className={styles.content1}>
                        <div className={styles.title}>{i18n.t("introduction.title2")}</div>
                        <div className={styles.des}>{i18n.t("introduction.title2-1")}</div>
                        <div className={styles.list}>
                            <img className={styles.img} src={require("static/images/product/product2-1.png")} alt="" />
                        </div>
                    </div>
                }
                {
                    currentMenu === 3 && <div className={styles.content1}>
                        <div className={styles.title}>{i18n.t("introduction.title3")}</div>
                        <div className={styles.des}>{i18n.t("introduction.title3-1")}</div>
                        <div className={styles.list}>
                            <img className={styles.img} src={require("static/images/product/product3-1.png")} alt="" />
                        </div>
                    </div>
                }

                {
                    currentMenu === 4 && <div className={styles.content1}>
                        <div className={styles.title}>{i18n.t("introduction.title4")}</div>
                        <div className={styles.list}>
                            <img className={styles.img} src={require("static/images/product/product4-1.png")} alt="" />
                        </div>
                    </div>
                }
            </div>


            <div className={styles.block1 + ' ' + styles.background1}>
                <div style={{ marginLeft: "0.6rem", width: "25%" }}>
                    <div className={styles.title}>{i18n.t("introduction.TV1")}</div>
                    <div className={styles.msg}>{i18n.t("introduction.TV1-1")}</div>
                    <img style={{ width: "75%", marginTop: '.4rem' }} src={require("static/images/product/block1-1.png")} alt="" />

                </div>
                <img style={{ marginRight: "0.22rem", marginLeft: "-0.6rem", }} src={require("static/images/product/block1.png")} alt="" />
            </div>
            <div className={styles.block1 + ' ' + styles.background2}>
                <img style={{ marginRight: "0.22rem", marginLeft: "-0.6rem", }} src={require("static/images/product/block2.png")} alt="" />
                <div style={{ marginLeft: "0.6rem", }}>
                    <div className={styles.title}>{i18n.t("introduction.TV2")}</div>
                    <div className={styles.des1} style={{ marginTop: "1rem", marginLeft: '.5rem' }}><span className={styles.redot}></span><span dangerouslySetInnerHTML={{
                        __html: i18n.t("introduction.TV2-1")
                    }}></span></div>
                    <div className={styles.des1} style={{ marginTop: ".2rem", marginLeft: '.5rem' }}><span className={styles.redot}></span><span dangerouslySetInnerHTML={{
                        __html: i18n.t("introduction.TV2-2")
                    }}></span></div>
                </div>
            </div>
            <div className={styles.block1 + ' ' + styles.background1}>
                <div style={{ marginLeft: "0.6rem", width: "30%" }}>
                    <div className={styles.title}>{i18n.t("introduction.TV3")}</div>
                    <img style={{ width: "80%" }} src={require("static/images/product/block3-1.png")} alt="" />

                </div>
                <img style={{ marginRight: "0.22rem", marginLeft: "-0.6rem", }} src={require("static/images/product/block3.png")} alt="" />
            </div>
            <div className={styles.block1 + ' ' + styles.background2}>
                <img style={{ marginRight: "0.22rem", marginLeft: "-0.6rem", }} src={require("static/images/product/block4.png")} alt="" />

                <div style={{ marginLeft: "0.6rem", width: "30%" }}>
                    <div className={styles.title}>{i18n.t("introduction.TV4")}</div>
                    <div className={styles.msg}>{i18n.t("introduction.TV4-1")}</div>
                    <img style={{ width: "80%" }} src={require("static/images/product/block4-4.png")} alt="" />

                </div>
            </div>
            <Introduction style={{ position: "initial", padding: "0.33rem 0 0.26rem" }} />
        </div >
    }
}

export default Page404;
