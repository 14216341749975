import About from "../pages/about-us"
import Download from "../pages/download"
import ProductIntroduction from "../pages/product-introduction";
import ProductG10 from "../pages/product-g10";
import ProductM120 from "../pages/product-m120";
import Solution from "../pages/solution";
import Home from "../pages/home";

const defaultRoutes = [
    { path: "/", component: Home },
    { path: "/about", component: About },
    { path: "/download", component: Download },
    { path: "/introduction", component: ProductIntroduction },
    { path: "/g10", component: ProductG10 },
    { path: "/m120", component: ProductM120 },
    { path: "/solution", component: Solution },
    { path: "/home", component: Home },
]

export const routes = [
    ...defaultRoutes,
];