import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";

interface IState {
}

class Index extends React.Component {

    public state: IState = {
    }

    public render() {
        return <div className={styles.m120Page}>
            <div className={styles.banner}>
                <div>{i18n.t('introduction.m120')}</div>
                <div>{i18n.t('introduction.m1201')}</div>
            </div>

            <div className={styles.block2}>
                <div className={styles.title}>{i18n.t("introduction.m1202")}</div>
                <div className={styles.msg}>{i18n.t("introduction.m1203")}</div>
            </div>

            <div className={styles.block3}>
                <div className={styles.title}>{i18n.t('introduction.m1204')}</div>
                <div className={styles.bottom}>
                    <div className={styles.item}>
                        <div>“真实展现每一张面孔”</div>
                        <img src={require("static/images/product/m120-31.png")} alt="" />
                        <div>矫正畸形，还原本貌</div>
                    </div>
                    <div className={styles.item}>
                        <div>“真实展现每一张面孔”</div>
                        <img src={require("static/images/product/m120-32.png")} alt="" />
                        <div style={{ textAlign: "center" }}>Sony 1/2.3‘’ CMOS 1200万像素 Sensor<br />4K Ultra HD分辨率</div>
                    </div>
                    <div className={styles.item}>
                        <div>“听得清每一句精彩发言”</div>
                        <img src={require("static/images/product/m120-33.png")} alt="" />
                        <div style={{ textAlign: "center" }}>内置6mic阵列，8米半径，<br />180度全向拾音，智能通话降噪</div>
                    </div>
                </div>
            </div>

            <div className={styles.block5}>
                <div className={styles.title}>{i18n.t('introduction.title1')}</div>
                <div className={styles.msg}>{i18n.t('introduction.title2')}</div>
                <div className={styles.bottom}>
                    <img src={require("static/images/product/m120-41.png")} alt="" />
                    <img src={require("static/images/product/m120-42.png")} alt="" />
                    <img src={require("static/images/product/m120-43.png")} alt="" />
                    <img src={require("static/images/product/m120-44.png")} alt="" />
                </div>
            </div>

            <div className={styles.block4}>
                <div className={styles.title}>{i18n.t('introduction.m1205')}</div>
                <div className={styles.msg}>{i18n.t('introduction.m1206')}</div>
                <div className={styles.bottom}>
                    <div className={styles.item}>
                        <img src={require("static/images/product/m120-51.png")} alt="" />
                        <div>人脸/人体/手势识别</div>
                    </div>
                    <div className={styles.item}>
                        <img src={require("static/images/product/m120-52.png")} alt="" />
                        <div>动态Pan/ Tilt/ Zoom调整视角</div>
                    </div>
                    <div className={styles.item}>
                        <img src={require("static/images/product/m120-53.png")} alt="" />
                        <div>特写抓拍</div>
                    </div>
                </div>
            </div>

            {/* <div className={styles.block3}>
                <div className={styles.title}>
                    <span>{i18n.t("introduction.g1010")}</span>  |  <span>{i18n.t("introduction.g1011")}</span>  |  <span>{i18n.t("introduction.g1012")}</span>  |  <span>{i18n.t("introduction.g1013")}</span>
                </div>
                <div className={styles.imgs}>
                    <img src={require("static/images/product/gaowen.PNG")} alt="" />
                    <img src={require("static/images/product/IP56.PNG")} alt="" />
                    <img src={require("static/images/product/rohs.PNG")} alt="" />
                    <img src={require("static/images/product/SRRC.PNG")} alt="" />
                </div>
            </div> */}

            <Introduction style={{ position: "initial", padding: "0.17rem 0 0.35rem" }} />
        </div>
    }
}

export default withRouter(Index);