import React from "react";
import { Introduction } from "components";
import styles from "./index.module.css";
import { withRouter } from "react-router-dom";
import Swiper1 from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

interface IState {
}

class Index extends React.Component<any>{

    public state: IState = {
    }

    public componentDidMount() {
        this.swiperInit()
    }

    public render() {
        return <div className={styles.homePage}>
            <div className={styles.banner}></div>
            <div className={styles.banner1}>
                <div className={styles.left}>
                    <div className={styles.text1}>G10</div>
                    <div className={styles.text2}>高清多场景<span>智能采拍</span>眼镜</div>
                    <div className={styles.btn} onClick={() => this.props.history.push("/g10")}>了解更多</div>
                </div>
                <div className={styles.right}>
                    <div className={styles.text1}>M120</div>
                    <div className={styles.text2}>面向大屏的<span>AI</span>终端</div>
                    <div className={styles.btn} onClick={() => this.props.history.push("/m120")}>了解更多</div>
                </div>
            </div>
            <div className={styles.banner2}>
                <div className={styles.title}>随闻悦见</div>
                <div className={styles.title2}>视频会议核心技术与全套方案</div>
                <div className={styles.btn} onClick={() => this.props.history.push("/introduction")}>了解更多</div>
            </div>
            <div className={styles.banner3}>
                <div className={styles.title}>硬核技术与实力，与用户与伙伴共赢！</div>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <img className={styles.jiantou} src={require("static/images/home/jiantou.png")} alt="" onClick={()=>{
                        this.swiper.slidePrev()
                    }}/>
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/1.PNG")} alt="" /></div>
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/6.png")} alt="" /></div>
                            <div className="swiper-slide" ><img className={styles.homeSwiperImg} src={require("static/images/svn/5.png")} alt="" /></div>
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/7.png")} alt="" /></div>
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/2.PNG")} alt="" /></div>
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/3.PNG")} alt="" /></div>
                            <div className="swiper-slide"><img className={styles.homeSwiperImg} src={require("static/images/svn/4.PNG")} alt="" /></div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                    <img className={styles.jiantou} src={require("static/images/home/jiantou.png")} alt="" onClick={()=>{
                        this.swiper.slideNext()
                    }}/>
                </div>

            </div>
            <Introduction />
        </div>
    }

    public swiperInit() {
        // @ts-ignore
        this.swiper = new Swiper1('.swiper-container', {
            slidesPerView: 5,
            spaceBetween: 21,
            // centeredSlides: true,

            // loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            // loopedSlides: 1,
        })
    }

    public swiper;
}

export default withRouter(Index);