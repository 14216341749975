export const MEETING_BASE_URL = "https://ml.svmeet.com";
export const version = "2.2.0";

export const API_URL = "/api"

export const STORAGE_ACCESS_TOKEN = "personal_access_token";
export const USER_DATA = "user";
export const CURRENT_MEETING_DATA = "current_meeting_data";
export const SETTING_PARAMS = "setting_params";
export const IS_EXISTING_USER = "is_existing_user";
export const MEET_PROVISIONAL_USERNAME = "meet_provisional_username";
export const GUIDE_PAGE = 'guide_page';
export const USER_SET_PASSWORD = "user_set_password"; // 老用户是否设置了密码

export function LogoutClearStorage() {
    window.localStorage.removeItem(STORAGE_ACCESS_TOKEN);
    window.localStorage.removeItem(USER_DATA);
    window.localStorage.removeItem(CURRENT_MEETING_DATA);
    window.localStorage.removeItem("noticeSocket")
}

export const dateFormat = "yyyy-MM-DDTHH:mm:ss.SSSZZ";

// export const mobileDownUrl = window.location.origin + "/download/android.apk";

export const mobileDownUrl = `http://www.svmeet.com:9000/android/svmeet/phone/latest-android.apk`//安卓下载的app路径

export const windowsUrl = `https://www.svmeet.com:19000/electron/prop/svmeet_setup_latest.exe`

export const macUrl = `https://www.svmeet.com:19000/electron/prop/svmeet_setup_latest.dmg`

export const androidTvUrl = `https://www.svmeet.com:19000/android/svmeet/tv/last-android-tv.apk`//安卓tv下载路径